import Parallax from "./components/Parallax";

export default class Application{
    private headerParallax:Parallax;

    constructor(){
        this.initParalllax();
        this.initText();
    }

    private initParalllax(){
        //this.headerParallax = new Parallax([".Parallax1", ".Parallax2"], 0.4);
    }
    private initText(){
        let el:HTMLElement = document.querySelector("#title h1 .black-letters");
        let shadow:string = "";
        for (let i = 0; i < 10; i++){
            shadow += (shadow ? "," : "") + -i + "px " + i + "px 0 #C8C8C8";
            //shadow += (shadow ? "," : "") + -i + "px " + i + "px 0 rgba(163, 163, 163, " + ((1 / 15) * ((15 - i) / 2) ) + ")";
        }
        el.style.textShadow = shadow;



        el = document.querySelector("#title h1 .blue-letter");
        shadow = "";
        for (let i = 0; i < 10; i++) {
            shadow += (shadow ? "," : "") + -i + "px " + i + "px 0 #153673";
        }
        el.style.textShadow = shadow;
    }
}